import env from "./env";
const server = env.apiLink;
const awsServer = env.awsLink;

const api = {
  // auth
  login: `${server}auth/login`,

  //users
  getUsers: `${server}admin/users`,
  getUserById: `${server}admin/users`,

  //courses
  getCoursesList: `${server}admin/courses`,
  getCourseById: `${server}admin/courses/`,
  publishCourse: `${server}admin/courses/`,
  getRssFeeds: (feedId) => `${server}admin/rss/${feedId}`,
  getRssFeedDetail: (urlSegment, title, lastBuildDate) =>
    `${server}admin/rss/detail/${urlSegment}/${title}/${lastBuildDate}`,
  getOldFeeds: (feedId) => `${server}admin/rss/all/${feedId}`,
  deleteFeed: (feedId) => `${server}admin/rss/${feedId}`,
  getFeeds: (includeDeleted = false) =>
    `${server}rss/all/${includeDeleted ? "?includeDeleted=true" : ""}`,
  getAdminFeeds: (includeDeleted = false) =>
    `${server}admin/rss/all/${includeDeleted ? "?includeDeleted=true" : ""}`,
  getFeedSource: (includeDeleted = false) =>
    `${server}admin/rss-source${includeDeleted ? "?includeDeleted=true" : ""}`,
  saveFeeds: `${server}admin/rss/saveFeeds`,
  getFeedXmlByLink: `${server}admin/rss-feed`,
  assignFeedToDeveloper: `${server}admin/rss-feed/assign`,
  getOldFeedsImgs: (lastBuildDate) =>
    `${server}admin/rss/all-imgs/${lastBuildDate}`,
  deleteSource: (sourceId, force = false) =>
    `${server}admin/rss-source/${sourceId}${force ? "?force=true" : ""}`,

  //trainer courses
  trainerCourses: (trainerUserId) =>
    `${server}admin/courses/trainer-courses/?trainerUserId=${trainerUserId}`,
  trainerCourseCounts: (trainerUserId) =>
    `${server}admin/courses/trainer-courses/count?trainerUserId=${trainerUserId}`,

  // transactions
  getTrainerCourseSales: () => `${server}admin/orders/trainer/sales`,

  //AWS services
  createProvision: () => `${awsServer}create-provision`,
  terminateEC2: () => `${awsServer}terminate-ec2`,
  stopEC2: () => `${awsServer}stop-ec2`,
};

export default api;
